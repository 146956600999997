<template>
  <div class="ulp">
    <el-upload class="avatar-uploader" action="" :accept="accept" :show-file-list="false"
      :http-request="handleAvatarSuccess">
      <img v-if="baseUrl" :src="baseUrl" :style="{ width: width, height: height, borderRadius: border_radius }">
      <video controls width="100%" v-else-if="video">
        <source :src="video" type="">
        Sorry, your browser doesn't support embedded videos.
      </video>

      <i v-else class="el-icon-plus avatar-uploader-icon" :style="{ width: width, height: height, lineHeight: height }" />
      <el-progress class="pres" v-if="isPres" :percentage="percent" :stroke-width="9" />
    </el-upload>
  </div>
</template>

<script>
import { qiniu_upload } from '@/api/commit'
import service from '@/utils/request2'
export default {
  data() {
    return {
      percent: 0,
      isPres: false, //进度条
      baseUrl: '',
      video: ''
    }
  },
  props: {
    imgUrl: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "178px",
    },
    height: {
      type: String,
      default: "178px",
    },
    border_radius: {
      type: String,
      default: "0%",
    },
    accept: {
      type: String,
      default: "image/*",
    }
  },
  watch: {
    imgUrl(val) {
      if (val == service.defaults.baseURL) {
        this.baseUrl = ''
      } else {
        if (val.indexOf('mp4') == -1) {
          this.baseUrl = val
        } else {
          this.video = val
        }

      }
    },
  },
  model: { prop: 'imgUrl', event: 'change' },
  methods: {
    handleAvatarSuccess(file) {
      console.log(file)
      qiniu_upload({ file: file.file }).then(res => {
        if (res.code == 200) {
          console.log(res)
          this.$message.success('上传成功')
          // this.baseUrl = this.$commit.getImg1()
          console.log('http://sf.aiyuechen.com//' + res.data)
          this.$emit('change', 'http://sf.aiyuechen.com//' + res.data)
        }
      })
    },
    // //文件发生改变触发;
    // imgChange(val){
    // },
  },
}
</script>

<style lang="scss">
.ulp {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: width;
    height: 178px;
    display: block;
  }
}
</style>